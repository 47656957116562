import React, { useEffect, useState } from "react";
import Image from "react-bootstrap/Image";
import '../assets/scss/_common.scss';
import Button from 'react-bootstrap/Button';
import "react-lazy-load-image-component/src/effects/blur.css";
import { Link } from "gatsby";
import campaigns from "../assets/images/campaigns.png";

export default function Automating() {

  const [isMobile, setIsMobile] = useState(false);
  const [isPad, setIsPad] = useState(false);


  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      setIsPad(window.innerWidth >= 1170 && window.innerWidth <= 1230);
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Initial call to set initial values
    handleResize();

    // Clean up the event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const backgroundColor = '#abb8c3';
  const borderRadius = '15px';
  
  const imageHeight = isMobile ? 'auto' : isPad ? '500px' : 'auto';
  const imageWidth = isMobile ? '100vw' : isPad ? '500px' : '646px';
  
  return (
    <div className="container my-5">
      {isMobile ? (
        <>
          <div className="row">
            <div className="col-md-12 d-flex justify-content-center align-items-center my-3 mx-1">
              <Image className={`${isMobile ? 'p-4 ms-3' : ''}`}
                src={campaigns}
                alt="Responsive Image"
                style={{ width: imageWidth, height: imageHeight, borderRadius: borderRadius, backgroundColor: backgroundColor }}
              />
            </div>
            <div className="col-12 text-center">
              <h2 className={`${isMobile ? 'text-left my-4 mx-3' : 'text-left my-4 accordian-img'}`}>
                <span className="fs-1 text-sm line-height-sm fw-bold">Automated Follow-ups,</span>
                <br />
                <span className="fs-1 text-sm line-height-sm fw-bold">Conversion Tracking, and</span>
                <br />
                <span className="fs-1 text-sm line-height-sm fw-bold ">A/B Testing - We've Got</span>
                <br />
                <span className="fs-1 text-sm line-height-sm fw-bold ">You Covered!</span>
              </h2>
              <label className="fs-5 my-4 mx-2">


                <ul className="auto">
                  <li className={`${isMobile ? 'text-start mb-2 mx-3' : 'text-start automation-padd accordian-img mb-2'}`}>
                    <span className="mr-3 mx-3">
                      Effortlessly stay in touch with your connections by automating follow-up messages and interactions.</span>
                  </li>
                  <li className={`${isMobile ? 'text-start mb-2 mx-3' : 'text-start automation-padd accordian-img mb-2'}`}>
                    <span className="mr-3 mx-3">Easily monitor and measure the effectiveness of your LinkedIn campaigns, helping you identify   what's  working and what needs improvement.</span>
                  </li>
                  <li className={`${isMobile ? 'text-start mb-2 mx-3' : 'text-start automation-padd accordian-img mb-2'}`}>
                    <span className="mr-3 mx-3">Fine-tune your strategies with  A/B testing, allowing  you to experiment with different approaches and  discover the  most effective tactics for your audience.</span>
                  </li>
                </ul>

              </label>
              <div className={`${isMobile ? ' mx-5' : 'mx-3'}`}>
                <Link to="https://app.dripfunnel.com/registration/" target="_blank">
                  <Button className="primary-color" size="lg">
                    <span className="nectar-menu-icon svg-icon">
                      <svg
                        role="presentation"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 32 32"
                        fill="white"
                      >
                        <path
                          d="M10.959 31.651c-0.093 0-0.191-0.019-0.280-0.060-0.299-0.139-0.451-0.472-0.361-0.789l3.628-12.745-8.113 1.695c-0.217 0.039-0.437-0.024-0.596-0.169-0.159-0.151-0.233-0.369-0.199-0.588l2.456-15.415c0.044-0.268 0.248-0.481 0.512-0.544l11.433-2.667c0.237-0.060 0.492 0.025 0.653-0.211 0.164 0.188 0.208 0.448 0.120.677l-3.319 8.601 9.243-2.399c0.268-0.075 0.552 0.031 0.713 0.257 0.159 0.225 0.164 0.528 0.008 0.752l-15.341 22.881c-0.129 0.195-0.341 0.301-0.557 0.301zM14.889 16.513c0.184 0 0.361 0.076 0.487 0.213 0.159 0.169 0.219 0.412 0.153 0.636l-2.773 9.751 12.012-17.916-8.804 2.283c-0.245 0.057-0.5-0.019-0.665-0.200-0.167-0.191-0.212-0.451-0.125-0.685l3.336-8.639-9.775 2.277-2.233 14.021 8.247-1.721c0.049-0.015 0.095-0.020 0.141-0.020z"
                        ></path>
                      </svg>
                    </span>
                    Start free trial
                  </Button>
                </Link>
                <div className="mt-2 fst-italic">*No Credit Card Required</div>
              </div>
            </div>

          </div>
        </>
      ) : (
        <>
          <div className="row">
            <div className="col-md-5">
              <h2 className="text-left">
                {isPad ? (<>
                  <span className="fs-1 fw-600  ">Automated Follow-ups,</span>

                  <span className="fs-1 fw-600  ">Conversion Tracking, and</span>

                  <span className="fs-1 fw-600  "> A/B Testing - We've Got</span>

                  <span className="fs-1 fw-600  ">You Covered!</span>
                </>
                ) : (<>
                  <span className=" display-6 fw-500  line-height">Automated Follow-ups,</span>
                  <br />
                  <span className=" display-6 fw-500  line-height">Conversion Tracking, and</span>
                  <br />
                  <span className=" display-6 fw-500  line-height">A/B Testing - We've Got</span>
                  <br />
                  <span className=" display-6 fw-500  line-height">You Covered!</span>
                </>)}

              </h2>
              <label className="fs-5 my-4">
                {isPad ? (
                  <>
                    <ul className="auto">
                      <li className="mb-2">
                        <span className="mr-3">
                          &nbsp;Effortlessly stay in touch with your connections by  automating follow-up messages and interactions.</span>
                      </li>
                      <li className="mb-2">
                        <span className="mr-3"> Easily monitor and measure the effectiveness of your LinkedIn campaigns, helping you identify what's working and what needs improvement.</span>
                      </li>
                      <li className="mb-2">
                        <span className="mr-3">&nbsp;Fine-tune your strategies with A/B testing, allowing  you to experiment with different approaches and  discover the most effective tactics for your audience.</span>
                      </li>
                    </ul>
                  </>
                ) : (
                  <>
                    <ul className="auto">
                      <li className="mb-2">
                        <span className="mr-3">
                          &nbsp;Effortlessly stay in touch with your connections by   &nbsp;  &nbsp; &nbsp;  &nbsp; automating follow-up messages and interactions.</span>
                      </li>
                      <li className="mb-2">
                        <span className="mr-3"> Easily monitor and measure the effectiveness of  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; your LinkedIn campaigns, helping you identify what's &nbsp; &nbsp;  working and what needs improvement.</span>
                      </li>
                      <li className="mb-2">
                        <span className="mr-3">&nbsp;Fine-tune your strategies with A/B testing, allowing &nbsp; &nbsp; &nbsp; you to experiment with different approaches and &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; discover the most effective tactics for your audience.</span>
                      </li>
                    </ul>
                  </>)}

              </label>
              <div className="mx-3">
                <Link to="https://app.dripfunnel.com" target="_blank">
                  <Button className="primary-color" size="lg">
                    <span className="nectar-menu-icon svg-icon">
                      <svg
                        role="presentation"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 32 32"
                        fill="white"
                      >
                        <path
                          d="M10.959 31.651c-0.093 0-0.191-0.019-0.280-0.060-0.299-0.139-0.451-0.472-0.361-0.789l3.628-12.745-8.113 1.695c-0.217 0.039-0.437-0.024-0.596-0.169-0.159-0.151-0.233-0.369-0.199-0.588l2.456-15.415c0.044-0.268 0.248-0.481 0.512-0.544l11.433-2.667c0.237-0.060 0.492 0.025 0.653-0.211 0.164 0.188 0.208 0.448 0.120.677l-3.319 8.601 9.243-2.399c0.268-0.075 0.552 0.031 0.713 0.257 0.159 0.225 0.164 0.528 0.008 0.752l-15.341 22.881c-0.129 0.195-0.341 0.301-0.557 0.301zM14.889 16.513c0.184 0 0.361 0.076 0.487 0.213 0.159 0.169 0.219 0.412 0.153 0.636l-2.773 9.751 12.012-17.916-8.804 2.283c-0.245 0.057-0.5-0.019-0.665-0.200-0.167-0.191-0.212-0.451-0.125-0.685l3.336-8.639-9.775 2.277-2.233 14.021 8.247-1.721c0.049-0.015 0.095-0.020 0.141-0.020z"
                        ></path>
                      </svg>
                    </span>
                    Start free trial
                  </Button>
                </Link>
                <div className="mt-2 fst-italic">*No Credit Card Required</div>
              </div>
            </div>
            <div className="offset-1 col-md-6 d-flex justify-content-center align-items-center my-3">
              <Image
                src={campaigns}
                alt="Responsive Image"
                style={{ width: imageWidth, height: imageHeight, borderRadius: borderRadius, backgroundColor: backgroundColor }}
              />
            </div>
          </div>
        </>)}

    </div>
  );
}
