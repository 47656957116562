import React, { useEffect, useState } from "react";
import Image from "react-bootstrap/Image";
import Button from 'react-bootstrap/Button';
import '../assets/scss/_common.scss';
import "react-lazy-load-image-component/src/effects/blur.css";
import { Link } from "gatsby";
import campaigns from "../assets/images/workflow.gif";


export default function TestimonialsDetails() {
  const [isMobile, setIsMobile] = useState(false); 
  const [isPad, setIsPad] = useState(false);

  useEffect(() => {
    // Function to check if the window width is less than or equal to 768
    function checkIsMobile() {
      setIsMobile(window.innerWidth <= 768);
    }

    // Initial check when the component mounts
    checkIsMobile();

    // Add an event listener to update isMobile when the window is resized
    window.addEventListener('resize', checkIsMobile);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', checkIsMobile);
    };
  }, []);

  useEffect(() => {
    // Update the isMobile state when the window is resized
    const handleResize = () => {
      setIsPad(window.innerWidth >= 1170 && window.innerWidth <= 1230);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      // Remove the event listener when the component is unmounted
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div id="how-it-works"  className={`${isMobile ? 'container mb-4' : 'container mt-5'}`}>
      <div className="row">
        <div className={`${isMobile ? 'col-md-12 mx-3' : isPad ? 'col-md-6 d-flex mt-10' : 'col-md-6 d-flex mt-4'}`}>
          <Image className={`${isMobile ? 'testimonials-sm-img ' : 'testimonials-img '}`}
            src={campaigns}
            alt="Responsive Image"
          />
        </div>
        <div className={`col-md-${isMobile ? '12 text-center mx-3' : isPad ? '5 offset-1 text-left  mt-10' : '5 offset-1 text-left'}`}>
        {isMobile ? (
          <>
          <h2 className={`${isMobile ? 'text-left my-5' : 'text-left '}`}>
            <span className={`${isMobile ? 'display-6 text-sm  fw-bold letter-spacing' : ' '}`}>Our dynamic workflows</span>
           
            <span className={`${isMobile ? 'display-6 text-sm  fw-bold letter-spacing' : ''}`}>ensure your actions</span>
        
            <span className={`${isMobile ? 'display-6 text-sm  fw-bold letter-spacing' : ''}`}>appear genuinely</span>
       
            <span className={`${isMobile ? 'display-6 text-sm  fw-bold letter-spacing' : ''} `}>authentic.</span>
          </h2>
          </>
        ):(
          <>
          <h2 className={`${isMobile ? 'text-left my-5' : 'text-left '}`}>
            <span className={`${isMobile ? '' : ' display-6 fw-500  line-height'}`}>Our dynamic workflows </span>
           
            <span className={`${isMobile ? '' : 'display-6 fw-500  line-height'}`}>ensure your actions </span>
        
            <span className={`${isMobile ? '' : 'display-6 fw-500 line-height'}`}>appear genuinely </span>
       
            <span className={`${isMobile ? '' : 'display-6 fw-500 '} line-height`}>authentic.</span>
          </h2></>
        )
}
          
          {isMobile ? (
            <>
              <div className="text-align-justify">
                <label className="text-align-justify">
                  <span className={`${isMobile ? 'fs-5 fw-500' : ''}`}>Elevate your LinkedIn </span>
                 
                  <span className={`${isMobile ? 'fs-5 fw-500' : ''}`}>engagement with our dynamic </span>
               
                  <span className={`${isMobile ? 'fs-5 fw-500' : ''}`}>workflows, designed to make </span>
              
                  <span className={`${isMobile ? 'fs-5 fw-500' : ''}`}>your interactions feel truly </span>
              
                  <span className={`${isMobile ? 'fs-5 fw-500' : ''}`}> genuine, building stronger </span>
              
                  <span className={`${isMobile ? 'fs-5 fw-500' : ''}`}>connections and boosting your</span>
              
                  <span className={`${isMobile ? 'fs-5 fw-500' : ''}`}> success.</span>

                </label>
              </div>
            </>
          ) : (
            <>
              <h4 className={`${isMobile ? 'fs-6 my-4 text-left d-flex' : 'fs-5 my-4 lh-base'}`}>
                Elevate your LinkedIn {isMobile && <br />} engagement with our dynamic {isMobile && <br />} workflows, designed to make {isMobile && <br />} your interactions feel truly {isMobile && <br />} genuine, building stronger {isMobile && <br />} connections and boosting your {isMobile && <br />} success.
              </h4>
            </>
          )
          }

          <div className={`${isMobile ? ' mt-5 mx-5' : ''}`}>
            <Link to="https://app.dripfunnel.com/registration/" target="_blank">
              <Button className="primary-color" size="lg">
                <span className="nectar-menu-icon svg-icon me-1">
                  <svg
                    role="presentation"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 32 32"
                    fill="white"
                  >
                    <path
                      d="M10.959 31.651c-0.093 0-0.191-0.019-0.280-0.060-0.299-0.139-0.451-0.472-0.361-0.789l3.628-12.745-8.113 1.695c-0.217 0.039-0.437-0.024-0.596-0.169-0.159-0.151-0.233-0.369-0.199-0.588l2.456-15.415c0.044-0.268 0.248-0.481 0.512-0.544l11.433-2.667c0.237-0.060 0.492 0.025 0.653-0.211 0.164 0.188 0.208 0.448 0.120.677l-3.319 8.601 9.243-2.399c0.268-0.075 0.552 0.031 0.713 0.257 0.159 0.225 0.164 0.528 0.008 0.752l-15.341 22.881c-0.129 0.195-0.341 0.301-0.557 0.301zM14.889 16.513c0.184 0 0.361 0.076 0.487 0.213 0.159 0.169 0.219 0.412 0.153 0.636l-2.773 9.751 12.012-17.916-8.804 2.283c-0.245 0.057-0.500-0.019-0.665-0.200-0.167-0.191-0.212-0.451-0.125-0.685l3.336-8.639-9.775 2.277-2.233 14.021 8.247-1.721c-0.049-0.015-0.095-0.020-0.141-0.020z"
                    ></path>
                  </svg>
                </span>
                Start free trial
              </Button>
            </Link>
            <div  className={`${isMobile ? 'mt-2 fst-italic p-1 testimonials-sm-italic' : 'mt-2 fst-italic p-1'}`}>*No Credit Card Required</div>
          </div>

        </div>
      </div>
    </div>
  );
}
