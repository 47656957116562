import React, { useEffect, useState } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../assets/scss/_common.scss';
import avatar from "../assets/images/profile.jpg";
import Michael from "../assets/images/abc.jpg";
import Sarah from "../assets/images/zyx.jpg";
import emily from "../assets/images/emily.jpg";
import books from "../assets/images/1517761765703.jpg";

function Clients() {
    const [isMobileView, setIsMobileView] = useState(false);
    const [isPad, setIsPad] = useState(false);

    const [fontSize, setFontSize] = useState("auto");

    var settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 5,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]

    }



    useEffect(() => {
        const checkWindowWidth = () => {
            if (window.innerWidth <= 768) {
                setFontSize("20px");
                setIsMobileView(true);
            } else if (window.innerWidth >= 1170 && window.innerWidth <= 1230) {
                setFontSize("15px");
            }
            else {
                setIsMobileView(false);
                setFontSize("18px");
            }
        };

        checkWindowWidth();

        window.addEventListener("resize", checkWindowWidth);

        return () => {
            window.removeEventListener("resize", checkWindowWidth);
        };
    }, []);

    const textStyles = {
        fontSize: fontSize,
        textAlign: isMobileView ? "center" : "center",
    };

    const carouselWidth = isMobileView ? '109%' : '100%';

    const imgWidth = isMobileView ? '44px' : '44px';
    const imgHeight = isMobileView ? '44px' : '44px';


    useEffect(() => {
        // Update the isMobile state when the window is resized
        const handleResize = () => {
            setIsPad(window.innerWidth >= 1170 && window.innerWidth <= 1230);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            // Remove the event listener when the component is unmounted
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <div className='mb-3'>
            <div id="testimonials" className={`${isMobileView ? 'container' : 'container'}`}>
                <h2 className='text-center mt-5'>
                    <span className={`${isMobileView ? ' textAlign fs-4' : 'display-3 text-syle textAlign'}`} >Trusted by over 5000+ </span>
                    <br />
                    <span className={`${isMobileView ? ' textAlign fs-4' : 'display-3 text-syle textAlign'}`} >customers worldwide{' '}</span>
                    {isMobileView && <br />}
                    <span>
                        {isMobileView && <span>&nbsp;</span>}
                        <img className={`${isMobileView ? 'circle-sm-img' : 'circle-img'}`}
                            src="https://themenectar.com/salient/saas/wp-content/uploads/sites/40/2022/08/face.jpg"
                            alt=""
                        />
                    </span>
                    <span className={`${isMobileView ? '' : 'client-img'}`}>
                        {isMobileView && <span>&nbsp;</span>}
                        <img
                            className={`${isMobileView ? 'circle-sm-img' : 'circle-img'}`}
                            src="https://themenectar.com/salient/saas/wp-content/uploads/sites/40/2022/08/saas-face-2.jpeg"
                            alt=""
                        />
                    </span>
                    <span className={`${isMobileView ? '' : 'client-img2'}`}>
                        {isMobileView && <span>&nbsp;</span>}
                        <img
                            className={`${isMobileView ? 'circle-sm-img' : 'circle-img'}`}
                            src="https://themenectar.com/salient/saas/wp-content/uploads/sites/40/2022/08/face-2-1.jpg"
                            alt=""
                        />
                    </span>
                </h2>
            </div>

            <div className={`${isMobileView ? 'carousel-sm mb-0' : 'carousel'}`}>
                <Slider {...settings}>
                    <div className={`${isMobileView ? 'box' : 'box m-0'}`}>
                        <div className="center-card-content">
                           
                                <h3 style={textStyles} className={`${isMobileView ? 'mt-3' : ''}`}>
                                    {/* <span className="quote display-4 me-2">&#8220;</span> */}
                                    I've used DripFunnel for just a few months, and it's like having a super assistant on LinkedIn. My connections have grown, and I'm getting more leads than ever before. It's made my job easier!
                                </h3>
                                <div className='d-flex mt-2'>
                                    <div className='col-xxl-2'>
                                        <img src={Sarah} className=' rounded-circle' width={imgWidth} height={imgHeight} alt="Avatar"></img>
                                    </div>
                                    <div className={`${isMobileView ? 'mx-0 ms-2' : 'mx-0'}`}>
                                        <span className={`${isMobileView ? 'fs-4 text-white autor-review' : isPad ? ' autor-review customfs-12' : 'fs-4 text-white autor-review'}`}>Aura Brooks</span>
                                        <br />
                                        <span className={`${isMobileView ? 'text-white autor-review customfs-10 custom-mb' : isPad ? 'font-custom  autor-review customfs-12 custom-mb' : 'text-white autor-review customfs-10 custom-mb'}`}>Sales Manager, Owl Eyes</span>
                                    </div>

                                </div>

                            {/* <label className='slider-quote'></label> */}
                        </div>
                    </div>

                    <div className="box m-0">
                        <div className="center-card-content">
                            <h3 style={textStyles} className={`${isMobileView ? 'mt-3' : ''}`}>DripFunnel has changed the game for our business. It's a smart tool that helps us connect with the right people. Our sales have gone up, and we're saving time too. Great job, DripFunnel team!</h3>
                            <div className='d-flex mt-2'>
                                <div className='col-xxl-2'>
                                    <img src={emily} className=' rounded-circle' width={imgWidth} height={imgHeight} alt="Avatar"></img>
                                </div>
                                <div className={`${isMobileView ? 'mx-0 ms-2' : 'mx-0'}`}>
                                    <span className={`${isMobileView ? 'fs-4 text-white autor-review' : isPad ? 'autor-review' : 'fs-4 text-white autor-review'}`}>Emily White</span>
                                    <br />
                                    <span className={`${isMobileView ? 'text-white autor-review customfs-10 custom-mb' : isPad ? 'font-custom  autor-review customfs-12 custom-mb' : 'text-white autor-review customfs-10 custom-mb'}`}>CEO at Bright.</span >
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="box m-0">
                        <div className="center-card-content">
                            <h3 style={textStyles} className={`${isMobileView ? 'mt-3' : ''}`}> As the COO of our company, efficiency is key. DripFunnel has been a game-changer. It keeps our LinkedIn outreach on track, and our team is loving it. It's a must-have tool!</h3>
                            <div className='d-flex mt-2'>
                                <div className='col-xxl-2'>
                                    <img src={books} className=' rounded-circle' width={imgWidth} height={imgHeight} alt="Avatar"></img>
                                </div>
                                <div className={`${isMobileView ? 'mx-0 ms-2' : 'mx-0'}`}>
                                    <span className={`${isMobileView ? 'fs-4 text-white autor-review' : isPad ? ' autor-review' : 'fs-4 text-white autor-review'}`}>Michael Brown</span>
                                    <br />
                                    <span className={`${isMobileView ? 'text-white autor-review customfs-10 custom-mb' : isPad ? 'font-custom  autor-review customfs-12 custom-mb' : 'font-custom autor-review customfs-10 custom-mb'}`}>COO, TechPro Innovations</span >
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="box m-0">
                        <div className="center-card-content">
                            <h3 style={textStyles} className={`${isMobileView ? 'mt-3' : ''}`}> Managing our LinkedIn network used to be a challenge. DripFunnel has made it so much simpler. Our connections have grown, and we're making meaningful business connections. Highly recommended!</h3>
                            <div className='d-flex mt-2'>
                                <div className='col-xxl-2'>
                                    <img src={Sarah} className=' rounded-circle' width={imgWidth} height={imgHeight} alt="Avatar"></img>
                                </div>
                                <div className={`${isMobileView ? 'mx-0 ms-2' : 'mx-0'}`}>
                                    <span className={`${isMobileView ? 'fs-4 text-white autor-review' : isPad ? ' autor-review' : 'fs-4 text-white autor-review'}`}>Sarah Davis</span>
                                    <br />
                                    <span className={`${isMobileView ? 'text-white autor-review customfs-10 custom-mb' : isPad ? 'font-custom  autor-review customfs-12 custom-mb' : 'text-white autor-review customfs-10 custom-mb'}`}>Managing Director,</span >
                                    {/* <span className={`${isMobileView ? 'text-white autor-review customfs-10' : 'text-white autor-review customfs-10'}`}> Acme Consulting Group</span > */}
                                </div>
                            </div>


                        </div>
                    </div>
                    <div className={`${isMobileView ? 'box' : 'box m-0'}`}>
                        <div className="center-card-content">
                            <h3 style={textStyles} className={`${isMobileView ? 'mt-3' : ''}`}>
                                I've used DripFunnel for just a few months, and it's like having a super assistant on LinkedIn. My connections have grown, and I'm getting more leads than ever before. It's made my job easier!
                            </h3>
                            <div className='d-flex mt-2'>
                                <div className='col-xxl-2'>
                                    <img src={avatar} className=' rounded-circle' width={imgWidth} height={imgHeight} alt="Avatar"></img>
                                </div>
                                <div className={`${isMobileView ? 'mx-0 ms-2' : 'mx-0'}`}>
                                    <span className={`${isMobileView ? 'fs-4 text-white autor-review' : isPad ? ' autor-review' : 'fs-4 text-white autor-review'}`}>Johannus Vogel</span>
                                    <br />
                                    <span className={`${isMobileView ? 'text-white autor-review customfs-10 custom-mb' : isPad ? 'font-custom  autor-review customfs-12 custom-mb' : 'text-white autor-review customfs-10 custom-mb'}`}>Sales Manager, Owl Eyes</span >
                                </div>
                            </div>
                            {/* <label className='slider-quote'></label> */}
                        </div>
                    </div>
                </Slider>
            </div>
        </div>
    )
}

export default Clients;
