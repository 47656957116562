import * as React from "react";
import "../assets/scss/index.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import Acnavbar from "../components/Acnavbar";
import { useEffect } from "react";
import Hero from "../components/Hero";
import Clients from "../components/Clients";
import Services from "../components/Services";
import Workwithus from "../components/Workwithus";
import TestiMonialsDetails from "../components/TestiMonialsDetails";
import Automating from "../components/Automating";
import Audience from "../components/Audience";
import Pricing from "../components/Pricing";
import AskedQuestions from "../components/AskedQuestions";
import Footer from "../components/footer";
import Seo from "../shared/seo";
import Wrapper from "../components/Wrapper";
import dripfunnelfavicon from "../assets/images/favicon.ico";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import { isObject } from "lodash";
import useUtmTracking from "../util/utm";

const IndexPage = () => {
  const method = useUtmTracking();
  useEffect(()=>{
    method();
  },[])

  return (

    <Wrapper>
      <Seo
        title="DripFunnel: Drive sales on Autopilot"
        description="Drive Sales on Autopilot Even While You Sleep."
      />
      <TawkMessengerReact
        propertyId="6565c0ee26949f7911358399"
        widgetId="1hganj9tg"
      />
      <Acnavbar
        home={true}
        pathname={""}
        logo={dripfunnelfavicon}
        logoLogic={false}
      />
      <Hero />
      <Services />
      {/* <Workwithus /> */}
      <Clients />
      <TestiMonialsDetails />
      <Automating />
      <Audience />
      <Pricing />
      <AskedQuestions />

      <Footer />

      {/*<Awards />*/}
    </Wrapper>
  );
};

export default IndexPage;
