import React, { useEffect, useState, useRef } from "react";
import Image from "react-bootstrap/Image";
import '../assets/scss/_common.scss';
import Button from 'react-bootstrap/Button';
import { Link } from "gatsby";
import src from "../assets/images/white-border.png";

function Hero() {
  const [isMobileView, setIsMobileView] = useState(false);
  useEffect(() => {
    // Check if the window width is less than or equal to 768
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIsMobileView(true);
      } else {
        setIsMobileView(false);
      }
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Initial check on component mount
    handleResize();

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const containerStyle = {
    backgroundColor: '#fcb900',
    borderRadius: "15px",
    background: 'linear-gradient(to bottom right, #fcb900 0%, #fcb900 50%, #0074e4 50%, #0074e4 100%)',
  };
  const rowPaddingClass = isMobileView ? '' : '';
  const imageHeight = isMobileView ? 'auto' : '650px';
  const imageWeight = isMobileView ? '450px' : '620px';
  const exploreFeaturesFontSize = isMobileView ? '12px' : '14px';
  const buttonFontSize = isMobileView ? '12px' : '16px';

  const svgHeight = isMobileView ? '50px' : '50px';
  const svgWeight = isMobileView ? '50px' : '50px';

  const reveneHeight = isMobileView ? '100' : '150px';
  const reveneWeight = isMobileView ? '150px' : '250px';

  return (
    <div className="container mutual-container">
      <div className={`${isMobileView ? '' : 'd-flex justify-content-center align-items-start .d-none .d-lg-block .d-xl-none'}`}>
        <img
          className="img-graph d-none d-lg-block"
          data-delay="0"
          height={reveneHeight}
          width={reveneWeight}
          data-animation="none"
          src="https://themenectar.com/salient/saas/wp-content/uploads/sites/40/2022/08/sass-sales.jpg"
          alt="Your Alt Text Here"
          srcSet="https://themenectar.com/salient/saas/wp-content/uploads/sites/40/2022/08/sass-sales.jpg 600w, https://themenectar.com/salient/saas/wp-content/uploads/sites/40/2022/08/sass-sales-300x175.jpg 300w"

        />
      </div>
      <div className={`row ${rowPaddingClass}`}>

        <div className={`${isMobileView ? 'col-md-12 d-flex flex-column align-items-center  my-5' : 'col-md-6 d-flex flex-column align-items-start'}`}>
          <h1 className={`${isMobileView ? '' : 'pt-5  mt-4'}`}>
            <span><strong className={`${isMobileView ? 'display-5 fw-medium' : 'display-3 fw-medium fw-700'}`}>Drive Sales on</strong></span>
            {isMobileView ? null : <br />}
            <span> <strong className={`${isMobileView ? 'display-5 fw-medium' : 'display-2 fw-medium'}`}>Autopilot,</strong></span>
            <br />
            <span><strong className={`${isMobileView ? 'display-5 fw-medium' : 'display-2 fw-medium'}`}>Even While</strong></span>
            <br />
            <span><strong className={`${isMobileView ? 'display-5 fw-medium' : 'display-2 fw-medium'}`}>You Sleep.</strong></span>
            <span className={`${isMobileView ? 'd-block' : ''}`}>
              <svg
                className="nectar-scribble basic-underline"
                role="presentation"
                viewBox="-600 -50 730 120"
                preserveAspectRatio="none"
                width={`${isMobileView ? 150 : 300}`}
                height={`${isMobileView ? 35 : 70}`}
              >
                <path
                  d="m -383.25 -6 c 55.25 -22 130.75 -33.5 293.25 -38 c 54.5 -0.5 195 -2.5 401 15"
                  stroke="#ffce59"
                  pathLength="1"
                  stroke-width="14"
                  fill="none"
                />
              </svg></span>

          </h1>
          <div className={`${isMobileView ? 'me-5' : ''}`}>
            <Link to="https://app.dripfunnel.com/registration/" target="_blank" className={`${isMobileView ? 'me-4' : ''}`}>
              <Button className="primary-color px-4 py-2" size="lg" style={{ fontSize: buttonFontSize }}>
                Start free trial
              </Button>
            </Link>
            <i className="ms-4">
              <span className="im-icon-wrap mx-3">
                <span>
                  <svg
                    role="presentation"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 32 32"
                  >
                    <path
                      d="M14.253 30.18c-0.015 0-0.029 0-0.047-0.003-3.431-0.237-6.265-1.631-8.425-4.143-3.521-4.1-5.069-11.008-4.363-19.451 0.029-0.361 0.321-0.66 0.703-0.609 4.747 0.277 9.948 3.045 13.576 7.224 3.303 3.801 4.924 8.252 4.572 12.539-0.031 0.364-0.339 0.628-0.72 0.609-0.367-0.031-0.64-0.355-0.611-0.719 0.323-3.924-1.187-8.028-4.249-11.556-3.232-3.724-7.772-6.251-11.995-6.712-0.532 7.676 0.947 14.14 4.095 17.807 1.917 2.232 4.444 3.472 7.505 3.68 0.367 0.027 0.644 0.344 0.62 0.711-0.019 0.352-0.312 0.623-0.661 0.623zM17.748 31.361c-0.305 0-0.58-0.211-0.649-0.521-1.608-7.159-9.288-16.467-12.544-19.611-0.265-0.253-0.273-0.675-0.019-0.941 0.256-0.264 0.68-0.271 0.943-0.019 3.347 3.236 11.245 12.827 12.919 20.277 0.081 0.361-0.145 0.713-0.504 0.8-0.048 0.011-0.097 0.015-0.145 0.015zM19.671 24.289c-0.309 0-0.585-0.216-0.652-0.531-0.076-0.36 0.156-0.713 0.516-0.789 10.043-2.101 10.111-15.788 9.765-20.943-5.8 0.629-11.848 5.075-14.577 10.795-0.159 0.333-0.559 0.472-0.889 0.315-0.332-0.159-0.473-0.556-0.315-0.888 3.028-6.348 9.907-11.229 16.356-11.607 0.355-0.035 0.675 0.251 0.703 0.609 1.072 12.781-2.955 21.388-10.771 23.025-0.047 0.008-0.091 0.013-0.136 0.013zM17.747 18.057c-0.115 0-0.231-0.028-0.336-0.091-0.317-0.188-0.424-0.593-0.237-0.915 3.067-5.233 7.295-10.143 9.389-12.169 0.267-0.252 0.688-0.249 0.943 0.019 0.255 0.261 0.249 0.688-0.016 0.943-2.041 1.972-6.167 6.765-9.167 11.887-0.125 0.207-0.348 0.327-0.576 0.327z"
                    ></path>
                  </svg>
                </span>
              </span>
            </i>
            <a className="link_text underline-text fw-500" role="button" href="#how-it-works" style={{ fontSize: exploreFeaturesFontSize, color: 'black' }}>Explore features</a>
            <div className="fst-italic mt-2" style={{ fontSize: exploreFeaturesFontSize }}>*No Credit Card Required</div>
          </div>
        </div>
        <div className={`${isMobileView ? 'col-md-12 d-flex justify-content-center align-items-center my-3 img-position' : 'col-md-6 d-flex justify-content-center align-items-center my-3'}`} style={containerStyle}>
          <div className={`${isMobileView ? 'mt-3 mb-auto' : ''}`}>
            <img
              className="img-sm-graph d-block d-md-none"
              data-delay="0"
              height={reveneHeight}
              width={reveneWeight}
              data-animation="none"
              src="https://themenectar.com/salient/saas/wp-content/uploads/sites/40/2022/08/sass-sales.jpg"
              alt="Your Alt Text Here"
              srcSet="https://themenectar.com/salient/saas/wp-content/uploads/sites/40/2022/08/sass-sales.jpg 600w, https://themenectar.com/salient/saas/wp-content/uploads/sites/40/2022/08/sass-sales-300x175.jpg 300w"

            />
          </div>
          <Image
            src="https://themenectar.com/img/demo-media/saas/sass-guy.webp"
            alt="Responsive Image"
            style={{ width: imageWeight, height: imageHeight }}
          />
          <a href="#services" className="">

            <Image
              src={src}
              alt="Responsive Image"
              className={`${isMobileView ? 'video-sm-icon' : 'video-icon'}`}
              style={{ width: svgWeight, height: svgHeight }}
            />
            <span className={`${isMobileView ? 'video-sm-text mt-3 underline-text text-gap text-shadow' : 'underline-text video-text text-gap text-shadow'}`}>Watch Our 90 Sec Explainer Video</span>
          </a>
        </div>
      </div>
    </div >


  );
}

export default Hero;