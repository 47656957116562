import React, { useState, useEffect } from "react";
import Image from "react-bootstrap/Image";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';
import '../assets/scss/_common.scss';

export default function AskedQuestions() {
    const [isMobileView, setIsMobile] = useState(false);
    const [isPad, setIsPad] = useState(false);
  
  
  
    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
        setIsPad(window.innerWidth >= 1170 && window.innerWidth <= 1230);
      };
  
      // Add event listener for window resize
      window.addEventListener('resize', handleResize);
  
      // Initial call to set initial values
      handleResize();
  
      // Clean up the event listener on component unmount
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    // const imageHeight = isMobileView ? '363px' : '400px';
    const imageWeight = isMobileView ? '348px' : '500px';

    const  backgroundColor = '#fcb900';
    const  borderRadius = '15px';
    const  lineargradient = 'linear-gradient(-135deg, #ff6900 0%, #ff6900 50%, #fcb900 50%, #fcb900 100%)';

    return (
        <div id="faqs" className="container mb-5">
            <div className={isMobileView ? "row my-3 mx-2" : "row my-3"}>
                <h1 className="display-5 fw-500  text-center">Frequently asked questions</h1>
                <div className={isMobileView ? "col-12 my-3 ms-3" : "col-md-6"}>
                    <Accordion  className="m-0" defaultActiveKey={null}>
                        <Accordion.Item eventKey="0" className="faq-background">
                            <Accordion.Header  className="accordion-question">
                                <p className={isMobileView ? "fw-600" : "fs-5 fw-600"}>What is DripFunnel?</p></Accordion.Header>
                            <Accordion.Body className="mb-5">
                                <p  className={isMobileView ? "p-4 px-0" : "p-5"}>
                                    DripFunnel is a powerful LinkedIn automation tool designed to streamline your B2B sales and lead generation efforts on LinkedIn. It automates various tasks such as connection requests, messaging, and follow-ups to help you build meaningful connections and drive sales.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1" className="faq-background">
                            <Accordion.Header className="accordion-question">
                                <p className={isMobileView ? "fw-600" : "fs-5 fw-600 "}>How does DripFunnel work?</p></Accordion.Header>
                            <Accordion.Body>
                                <p className={isMobileView ? "p-4 px-0" : "p-5"}>
                                    DripFunnel operates by automating your LinkedIn outreach process. You can set up custom sequences, and the tool will send connection requests, messages, and follow-ups on your behalf. It also provides analytics to track your campaign's performance.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header className="accordion-question">
                                <p className={isMobileView ? "fw-600" : "fs-5 fw-600"}>Is my LinkedIn account safe when I use DripFunnel?</p></Accordion.Header>
                            <Accordion.Body>
                                <p className={isMobileView ? "p-4 px-0" : "p-5"}>
                                    DripFunnel is super safe to use on LinkedIn. We've got a bunch of cool safety stuff in place to keep your account secure. We act like real people, we use the cloud for performance, we've got unique IP-access, and we even have this special thing called Activity Control. It's like our superpower that makes sure LinkedIn doesn't think you're doing too much, and it might change your limits based on what LinkedIn says. So, you're in good hands with us!
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3" className="faq-background">
                            <Accordion.Header className="accordion-question">
                                <p className={isMobileView ? "fw-600" : "fs-5 fw-600"}>What benefits can I expect from using DripFunnel?</p></Accordion.Header>
                            <Accordion.Body>
                                <p className={isMobileView ? "p-4 px-0" : "p-5"}>
                                    By using DripFunnel, you can expect benefits such as increased lead generation, time savings, improved engagement with your network, and enhanced sales opportunities. It helps you scale your outreach efforts effectively.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                            <Accordion.Header className="accordion-question">
                                <p className={isMobileView ? "fw-600" : "fs-5 fw-600"}>Can I customize my outreach campaigns with DripFunnel?</p></Accordion.Header>
                            <Accordion.Body>
                                <p className={isMobileView ? "p-4 px-0" : "p-5"}>
                                    Absolutely! DripFunnel offers customization options for your campaigns. You can personalize messages, set specific targeting criteria, and design sequences tailored to your goals and audience.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="5">
                            <Accordion.Header className="accordion-question"> 
                            <p className={isMobileView ? "fw-600" : "fs-5 fw-600"}>Is there a free trial available?</p></Accordion.Header>
                            <Accordion.Body>
                                <p className={isMobileView ? "p-4 px-0" : "p-5"}>
                                    Yes, we offer a free trial period so you can explore DripFunnel's features and see how it can benefit your LinkedIn outreach efforts. Sign up to try it out today.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="6">
                            <Accordion.Header className="accordion-question">
                                <p className={isMobileView ? "fw-600" : "fs-5 fw-600"}>Is DripFunnel suitable for individuals and businesses of all sizes?</p></Accordion.Header>
                            <Accordion.Body>
                                <p className={isMobileView ? "p-4 px-0" : "p-5"}>
                                    Yes, DripFunnel is designed to cater to both individual professionals and businesses of all sizes. It can be customized to match your specific goals and requirements.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="7">
                            <Accordion.Header className="accordion-question">
                                <p className={isMobileView ? "fw-600" : "fs-5 fw-600"}>Is my data safe when using DripFunnel?</p></Accordion.Header>
                            <Accordion.Body>
                                <p className={isMobileView ? "p-4 px-0" : "p-5"}>
                                    We take data security seriously. DripFunnel uses industry-standard encryption and security measures to protect your information. Your data is safe with us.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
                <div className={`${isMobileView ? 'col-md-12 d-flex justify-content-center align-items-center my-3 ms-2' : isPad ? 'col-md-5 d-flex justify-content-center align-items-center my-3  faq-img-width ms-5' : ' col-md-5 d-flex justify-content-center align-items-center my-3  faq-img-width'}`} >
                    <Image
                        src="https://themenectar.com/salient/saas/wp-content/uploads/sites/40/2022/08/saas-girl.png"
                        alt="Responsive Image"
                        style={{ width: imageWeight,  backgroundColor:backgroundColor, borderRadius:borderRadius, background: lineargradient}}
                    />
                </div>
            </div>
        </div>
    );
}

