import { useEffect } from "react";
import { useParams } from 'react-router-dom';

interface UtmData {
  firstTouch: string | null;
  lastTouch?: string | null;
}

const updateCookie = <T extends UtmData>(cookieName: string, value: T) => {
  document.cookie = `${cookieName}=${JSON.stringify(
    value
  )}; domain=.dripfunnel.com; path=/`;
};

const getCookieValue = <T extends UtmData>(cookieName: string): T | null => {
  const cookieValue = document.cookie.match(
    `(^|;)\\s*${cookieName}\\s*=\\s*([^;]+)`
  );
  return cookieValue ? JSON.parse(cookieValue.pop() || "null") : null;
};

const useUtmTracking = () => {
  const { utm_source, utm_medium, utm_campaign } = useParams();
  const utmSource = utm_source || null;
  const utmMedium = utm_medium || null;
  const utmCampaign = utm_campaign || null;

  return () => {
    let lsUtmSource = getCookieValue<UtmData>("utm_source");
    let lsUtmMedium = getCookieValue<UtmData>("utm_medium");
    let lsUtmCampaign = getCookieValue<UtmData>("utm_campaign");

    if (utmSource || utmMedium || utmCampaign) {
      if (!lsUtmSource) {
        updateCookie("utm_source", { firstTouch: utmSource });
      } else {
        updateCookie("utm_source", { ...lsUtmSource, lastTouch: utmSource });
      }

      if (!lsUtmMedium) {
        updateCookie("utm_medium", { firstTouch: utmMedium });
      } else {
        updateCookie("utm_medium", { ...lsUtmMedium, lastTouch: utmMedium });
      }

      if (!lsUtmCampaign) {
        updateCookie("utm_campaign", { firstTouch: utmCampaign });
      } else {
        updateCookie("utm_campaign", {
          ...lsUtmCampaign,
          lastTouch: utmCampaign,
        });
      }

      lsUtmSource = getCookieValue<UtmData>("utm_source");
      lsUtmMedium = getCookieValue<UtmData>("utm_medium");
      lsUtmCampaign = getCookieValue<UtmData>("utm_campaign");
    }
  };
};

export default useUtmTracking;
