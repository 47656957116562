import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { graphql, useStaticQuery } from "gatsby"


const Seo = ({ title, description, image, article }: { title: string; description: string, image: string, article: string }) => {
    const { pathname } = useLocation()
    const { site } = useStaticQuery(query)

    const {
        defaultTitle,
        titleTemplate,
        defaultDescription,
        siteUrl,
        twitterUsername,
    } = site.siteMetadata

    const seo = {
        title: title || defaultTitle,
        description: description || defaultDescription,
        image: `${siteUrl}${image}`,
        url: `${siteUrl}${pathname}`,
    }

    return (
        <>
            <Helmet title={seo.title} titleTemplate={titleTemplate}>
                <link rel="sitemap" href="/sitemap/sitemap-0.xml" />

                <meta name="description" content={seo.description} />
                <meta name="image" content={seo.image} />
                <meta
                    name="ahrefs-site-verification"
                    content="1a61fb252a002d927596edd17086d735cdcef4afa2d5dee4d83633622d0904b6"
                />

                <meta property="og:type" content="website" />
                {seo.url && <meta property="og:url" content={seo.url} />}

                {(article ? true : null) && (
                    <meta property="og:type" content="article" />
                )}

                {seo.title && <meta property="og:title" content={seo.title} />}

                {seo.description && (
                    <meta property="og:description" content={seo.description} />
                )}

                {seo.image && <meta property="og:image" content={seo.image} />}

                <meta name="twitter:card" content="summary_large_image" />

                {twitterUsername && (
                    <meta name="twitter:creator" content={twitterUsername} />
                )}

                {seo.title && <meta name="twitter:title" content={seo.title} />}

                {seo.description && (
                    <meta name="twitter:description" content={seo.description} />
                )}

                {seo.image && <meta name="twitter:image" content={seo.image} />}

            </Helmet>
        </>
    )
}

export default Seo

Seo.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
    article: PropTypes.bool,
}

Seo.defaultProps = {
    title: null,
    description: null,
    image: null,
    article: false,
}

const query = graphql`
  query Seo {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl
      }
    }
  }
`
