import React from 'react'

const Wrapper = ({ children }: any) => {
    const [isAuth, setIsAuth] = React.useState(true);
    const [password, setPassword] = React.useState<any>(" ");

    React.useEffect(() => {
        if (password === "Softo") {
            setIsAuth(true);
        }
    }, [password]);

    const isProd: boolean = process.env.GATSBY_ENV === "production";

    if (!isProd && !isAuth) {
        return (
            <>
                <div>
                    <form>
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </form>
                </div>
            </>
        );
    }
    return (
        <>
            {children}
        </>
    )
}

export default Wrapper