import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import logo from "../assets/images/drip-funnel-white.svg";
import fb from "../assets/images/facebook.webp";
import ld from "../assets/images/linkedin.webp";
import ig from "../assets/images/instagram.webp";
import tw from "../assets/images/twitterIcon.webp";
import yu from "../assets/images/youtube.png";
import { Link } from "gatsby";
import Button from 'react-bootstrap/Button';
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import '../assets/scss/_common.scss';

function Footer() {
  const [isMobile, setIsMobile] = useState(false);
  const [isPad, setIsPad] = useState(false);



  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      setIsPad(window.innerWidth >= 1170 && window.innerWidth <= 1230);
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Initial call to set initial values
    handleResize();

    // Clean up the event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div  className={`${isMobile ? 'footer-bckg accordian-img' : 'footer-bckg'}`}>
      <Container className="px-3 px-lg-0">
        <div className="row pt-4 pt-lg-5">
          <div className={`${isMobile ? 'col-12 mb-3 mb-lg-0' : ' col-7 mb-3 mb-lg-0'}`}>
            <LazyLoadImage effect="blur" src={logo} className="white-logo" alt="Softobotics Logo" />
          </div>
          <div  className={`${isMobile ? 'col-12 mb-3 mb-lg-0' : ' col-5 mb-3 mb-lg-0 p-0'}`}>
            <a target="_blank" href="https://www.youtube.com/@DripFunnel">
              <LazyLoadImage className="fb-logo m-1" src={yu} alt="facebook-logo-white" />
            </a>
            <a target="_blank" href="https://www.facebook.com/dripFunnel">
              <LazyLoadImage className="fb-logo" src={fb} alt="facebook-logo-white" />
            </a>
            <a href="https://www.linkedin.com/company/dripfunnel/" target="_blank">
              <LazyLoadImage className="fb-logo" effect="blur" src={ld} alt="LinkedIn Icon" />
            </a>
            <a href="https://twitter.com/dripfunnel" target="_blank">
              <LazyLoadImage className="fb-logo" effect="blur" src={tw} alt="Twitter Icon" />
            </a>
            <a href="https://www.instagram.com/dripfunnelofficial/" target="_blank">
              <LazyLoadImage className="fb-logo" effect="blur" src={ig} alt="Instagram Icon" />
            </a>
          </div>
        </div>

        <div className="pt-4 pt-lg-5">
          <div className="d-lg-flex">
            <div className="col-lg-7 mb-4">
              <strong className="display-4" style={{ color: 'white' }}>Scale your brand and</strong>
              <br />
              <strong className="display-4" style={{ color: 'white' }}>earn more revenue</strong>
            </div>
            <div className="col-lg-5 text-left">
              <div className="row">
                <div className="col text-left">
                  <ul className="footer">
                    <li className="mb-2">
                      <a href="#testimonials" className={`${isPad ? 'underline-text m-2' : 'h6 underline-text m-2'}`}>Why DripFunnel</a>
                    </li>
                    <li className="mb-2">
                      <a href="#how-it-works" className={`${isPad ? 'underline-text m-2' : 'h6 underline-text m-2'}`}>How it works</a>
                    </li>
                    <li className="mb-2">
                      <a href="#pricing" className={`${isPad ? 'underline-text m-2' : 'h6 underline-text m-2'}`}>Pricing</a>
                    </li>
                    <li className="mb-2">
                      <a href="#faqs" className={`${isPad ? 'underline-text m-2' : 'h6 underline-text m-2'}`}>FAQS</a>
                    </li>
                  </ul>
                </div>
                <div className="col text-left">
                  <ul className="footer">
                    <li className="mb-2">
                      <a href="your-link-2" className="h6 underline-text border-0 m-2">Contact Us</a>
                    </li>
                    <li className="mb-2">
                      <a  href="tel:91-8147594806" className="h6 underline-text m-2">+91-8147594806</a>
                    </li>
                    <li className="mb-2">
                      <a  href="mailto:contact@dripfunnel.com" className="h6 underline-text m-2">contact@dripfunnel.com</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row my-5 pl-2 pr-2">
          <div className="col-lg-7 mb-4">
          <Link to="https://app.dripfunnel.com/registration/" target="_blank">
            <Button className="primary-color" size="lg">
              Start free trial
            </Button>
            </Link>
          </div>
          <div className="col-lg-4">
            <p className="me-1" style={{ color: 'white' }}>
              Copyright © 2023
              <a className="mx-1 underline-text" href="https://www.dripfunnel.com/" style={{ color: 'white' }}>
               DripFunnel 
              </a>
                 | All Rights Reserved
            </p>
          </div>
        </div>

        <div className="d-flex justify-content-between flex-wrap">
          <a className="text-white mb-2" href="/terms-of-use/">Terms of Service</a>
          <a className="text-white mb-2" href="/privacy-policy">Privacy Policy</a>
          <a className="text-white mb-2" href="/cookie-policy">Cookie Policy</a>
          <a className="text-white mb-2" href="/fair-billing-policy">Fair Billing Policy</a>
          <a className="text-white mb-2" href="/security-and-compliance">Security & Compliance</a>
          <a className="text-white mb-2" href="vulnerability-policy">Vulnerability Policy</a>
          <a className="text-white mb-2" href="/acceptable-use-policy">Acceptable Use Policy</a>
          <a className="text-white mb-2" href="/GDPR">GDPR</a>
          <a className="text-white mb-2" href="/data-processing-agreement">Data Processing Agreement</a>
        </div>


        <div className="disclaimer">Disclaimer</div>
        <div className="disclaimer pb-4">
          Dripfunnel is not endorsed by, affiliated or an official product of the Linkedin Corporation, registered in the U.S. and other countries. All LinkedIn™ logos and trademarks used and displayed are the property of the LinkedIn Corporation. Using Dripfunnel may not comply with Linkedin's User Agreement and you understand that you are using Dripfunnel at your own discretion and risk.
        </div>
      </Container>
    </div>
  );
}

export default Footer;
