import React, { useEffect, useState } from "react";
import Image from "react-bootstrap/Image";
import '../assets/scss/_common.scss';
import Button from 'react-bootstrap/Button';
import "react-lazy-load-image-component/src/effects/blur.css";
import { Link } from "gatsby";

export default function Audience() {
  const [isMobile, setIsMobile] = useState(false);
  const [isPad, setIsPad] = useState(false);



  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      setIsPad(window.innerWidth >= 1170 && window.innerWidth <= 1230);
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Initial call to set initial values
    handleResize();

    // Clean up the event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // const imageWeight = isMobile ? '100%' : '646px';
  const imageWidth = isMobile ? '100vw' : (isPad ? '500px' : '100%');
  const borderRadius = '15px';

  return (
    <div className="container my-5">
      <div className="row">
        <div className={`col-md-${isMobile ? '12 d-flex justify-content-center align-items-center mx-1' : '6'}`}>

          <Image
            src="https://themenectar.com/salient/saas/wp-content/uploads/sites/40/2022/08/surface-HVrda0rOHqk-unsplash-1.jpeg"
            alt="Responsive Image"
            className="ms-3"
            style={{
              width: imageWidth,
              borderRadius: '15px',
              margin: isMobile ? '0 auto' : '0',
            }}
          />
        </div>
        <div className={`col-md-${isMobile ? '12 ' : '5 offset-1 '}`}>
          <h2 className={`${isMobile ? 'text-left my-4 mx-2' : isPad ? 'my-3' : 'text-left line-height'}`}>
            <span className={`${isMobile ? 'display-5 fw-bold' : isPad ? 'fs-2 fw-600 ' : 'display-6 fw-500  line-height'}`}> Expand your LinkedIn </span>
            {!isMobile && !isPad && <br />}
            <span className={`${isMobile ? 'display-5 fw-bold' : isPad ? 'fs-2 fw-600 ' : 'display-6 fw-500  line-height'}`}> network with the right </span>
            {!isMobile && !isPad && <br />}
            <span className={`${isMobile ? 'display-5 fw-bold' : isPad ? 'fs-2 fw-600 ' : 'display-6 fw-500  line-height'}`}>audience,</span>
          </h2>

          <label className={`fs-5 my-4 ${isMobile ? 'text-center mx-2' : ''}`}>
            {isMobile ? (
              <>
                <ul className="auto">
                  <li className={`${isMobile ? 'text-start  mb-2' : ''}`}>
                    Connect with professionals who  matter to your career or  business.
                  </li>
                  <li className={`${isMobile ? 'text-start  mb-2' : ''}`}>
                    Attract valuable opportunities like  partnerships and clients.
                  </li>
                  <li className={`${isMobile ? 'text-start  mb-2' : ''}`}>
                    Elevate your professional reputation and impact your industry.
                  </li>
                </ul>
              </>
            ) : (
              <>
                {isPad ? (
                  <>
                    <ul className="auto">
                      <li className="">
                        &nbsp; Connect with professionals who matter to your career or business.
                      </li>
                      <li className="">
                        &nbsp; Attract valuable opportunities like partnerships and clients.
                      </li>
                      <li className="">
                        &nbsp; Elevate your professional reputation and impact your industry.
                      </li>
                    </ul>
                  </>
                ) : (
                  <>
                    <ul className="auto">
                      <li className='mb-4'>
                        &nbsp; Connect with professionals who matter to your &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;career or business.
                      </li>
                      <li className={`${isMobile ? '' : 'mb-4'}`}>
                        &nbsp; Attract valuable opportunities like partnerships and &nbsp; &nbsp; &nbsp; &nbsp;clients.
                      </li>
                      <li className={`${isMobile ? '' : 'mb-4'}`}>
                        &nbsp; Elevate your professional reputation and impact &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;your industry.
                      </li>
                    </ul>
                  </>)
                }

              </>
            )}
          </label>
          <div className={`${isMobile ? 'text-center p-3' : 'text-left mx-3'} ${isMobile ? 'mx-3' : 'mt-2'}`}>
            <Link to="https://app.dripfunnel.com/registration/" target="_blank">
              <Button className="primary-color mx-auto" size="lg">
                <span className="nectar-menu-icon svg-icon">
                  <svg
                    role="presentation"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 32 32"
                    fill="white"
                  >
                    <path
                      d="M10.959 31.651c-0.093 0-0.191-0.019-0.280-0.060-0.299-0.139-0.451-0.472-0.361-0.789l3.628-12.745-8.113 1.695c-0.217 0.039-0.437-0.024-0.596-0.169-0.159-0.151-0.233-0.369-0.199-0.588l2.456-15.415c0.044-0.268 0.248-0.481 0.512-0.544l11.433-2.667c0.237-0.060 0.492 0.025 0.653-0.211 0.164 0.188 0.208 0.448 0.120.677l-3.319 8.601 9.243-2.399c0.268-0.075 0.552 0.031 0.713 0.257 0.159 0.225 0.164 0.528 0.008 0.752l-15.341 22.881c-0.129 0.195-0.341 0.301-0.557 0.301zM14.889 16.513c0.184 0 0.361 0.076 0.487 0.213 0.159 0.169 0.219 0.412 0.153 0.636l-2.773 9.751 12.012-17.916-8.804 2.283c-0.245 0.057-0.5-0.019-0.665-0.200-0.167-0.191-0.212-0.451-0.125-0.685l3.336-8.639-9.775 2.277-2.233 14.021 8.247-1.721c0.049-0.015 0.095-0.020 0.141-0.020z"
                    ></path>
                  </svg>
                </span>
                Start free trial
              </Button>
            </Link>
            <div className={`fst-italic ${isMobile ? 'mt-2' : ''}`}>*No Credit Card Required</div>
          </div>
        </div>
      </div>
    </div>
  );
}
