import { Link } from "gatsby";
import React, { useEffect, useRef, useState } from "react";
import { Button, Dropdown } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "../assets/scss/headers.scss";
import '../assets/scss/index.scss';
import dripfunnelogo from "../assets/images/drip-funnel.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css"
import "../assets/scss/acnavbar.scss"
import '../assets/scss/_common.scss';

const Acnavbar = ({ home, pathname, logo, logoLogic }: { home: boolean; pathname: string, logo: string, logoLogic: boolean }) => {
  const [showDropServices, setShowDropServices] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [activeItem, setActiveItem] = useState('');
  const [isMobile, setIsMobileView] = useState(false);
  const [isPrivacyPolicyPage, setIsPrivacyPolicyPage] = useState(false);


  const handleClick = (item: any, event: any) => {
    event.stopPropagation();
    setActiveItem(item);
  };


  useEffect(() => {
    document.body.classList.add("home-page");
    typeof window !== "undefined" &&
      (window.onscroll = () => {
        if (window.scrollY < 60) {
          !!sticky.current && sticky.current.classList.remove("secondary");
          setScrolled(false);
        }
        else {
          !!sticky.current && sticky.current.classList.add("secondary");
          setScrolled(true);
        }
      });
    setIsPrivacyPolicyPage(pathname === '/privacy-policy' || pathname === '/terms-of-use');
  }, []);


  useEffect(() => {
    const checkIfMobileView = () => {
      setIsMobileView(window.innerWidth <= 768);
    };

    checkIfMobileView();

    window.addEventListener("resize", checkIfMobileView);
    return () => {
      window.removeEventListener("resize", checkIfMobileView);
    };
  }, []);


  const toggleMenu = () => {
    setShowMenu(prevState => !prevState);
  };



  const sticky = useRef();
  return (
    <div className={scrolled ? "blur-effect" : ""}>
      <Navbar
        className={`py-0 ${isPrivacyPolicyPage ? 'privacy-policy-bg' : ''}`}
        id="navbar-ac"
        collapseOnSelect
        sticky="top"
        expand="lg"
        variant="dark"
        ref={sticky}
      >
        <Container className="header-border p-0 py-1">
          <Navbar.Brand className="w-sidebar ms-md-3 ms-lg-0 d-flex justify-content-between flex-row-reverse cust-tab-width m-3">
            <div className="d-flex align-items-center mx-lg-3">
              <Link to="/" className="cust-link-mob-only">
                <LazyLoadImage
                  effect="blur"
                  className={scrolled ? "cust-mob-only" : "cust-mob-only"}
                  width={185}
                  src={logoLogic ? (scrolled ? dripfunnelogo : logo) : (scrolled ? dripfunnelogo : dripfunnelogo)}
                  alt="Logo"
                />
              </Link>

            </div>

            <Link to="https://forms.gle/2qkWwhpddLYBwVws8https://app.dripfunnel.com/auth/login/">
              <Button
                className="px-md-5 btn-sm header-login d-lg-none border-0 ms-5"
              >
                <small className="mx-3 fs-6">Login</small>
              </Button>
            </Link>
            <Navbar.Toggle aria-controls="responsive-navbar-nav"
              className="me-md-3 p-0  ms-md-0 border-0 "
              onClick={toggleMenu} >
              <svg width="30px" height="30px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 18H10" stroke="#000000" stroke-width="2" stroke-linecap="round" />
                <path d="M4 12L16 12" stroke="#000000" stroke-width="2" stroke-linecap="round" />
                <path d="M4 6L20 6" stroke="#000000" stroke-width="2" stroke-linecap="round" />
              </svg>
            </Navbar.Toggle>
          </Navbar.Brand>
          {isMobile ? (
            <>

              <Navbar.Collapse id="responsive-navbar-nav" className={`responsive-navbar-nav justify-content-end`}>
                <Nav className="main-nav-design">
                  <Dropdown className={`lh-2  ${logoLogic ? (scrolled ? '' : 'nav-link-blue') : (scrolled ? 'color-white  d-grid  mx-4 my-4' : 'color-white d-grid mx-4 my-4')}`} show={showMenu}
                    onMouseLeave={() => setShowMenu(false)}
                  >
                    <Navbar.Toggle aria-controls="responsive-navbar-nav"
                      className="me-md-3 p-0  ms-md-0 border-0 bg-dark toggle-btm-sm hunbumger-menu"
                      onClick={toggleMenu} >
                      <span aria-hidden="false">&times;</span>
                    </Navbar.Toggle>
                    <div>
                      <a href="#testimonials" className={`cust-select-color dark-color me-4 fs-1 fw-400`}>
                        <span style={{ borderBottom: '1px solid', marginTop: '5px', marginBottom: '10px' }}>Why DripFunnel?</span>
                      </a>
                    </div>

                    <a href="#pricing" className={`cust-select-color dark-color me-4 fs-1 fw-400 mr-2`} >
                      <span style={{ borderBottom: '1px solid', marginTop: '5px', marginBottom: '10px' }}>Pricing</span>
                    </a>
                    <a href="#how-it-works" className={`cust-select-color dark-color me-4 fs-1 fw-400 mr-2`}>
                      <span style={{ borderBottom: '1px solid', marginTop: '5px', marginBottom: '10px' }}>How it works</span>
                    </a>

                    <a href="#faqs" className={`cust-select-color dark-color me-4 fs-1 fw-400 mr-2`}>
                      <span style={{ borderBottom: '1px solid', marginTop: '5px', marginBottom: '10px' }}>FAQs</span>
                    </a>

                  </Dropdown>

                </Nav>

              </Navbar.Collapse>
            </>
          ) : (
            <>
              <Navbar.Collapse id="responsive-navbar-nav" className="responsive-navbar-nav justify-content-end">
                <Nav className="main-nav-design">
                  <Dropdown className={`lh-2  ${logoLogic ? (scrolled ? '' : 'nav-link-blue') : (scrolled ? 'color-white' : 'color-white')}`}
                    onMouseLeave={() => setShowDropServices(false)}
                  >
                    <a href="#testimonials" className={`cust-select-color dark-color me-4 fw-400`}>
                      <span style={{ borderBottom: '1px solid', marginTop: '5px', marginBottom: '10px' }}>Why DripFunnel?</span>
                    </a>

                    <a href="#pricing" className={`cust-select-color dark-color me-4 fw-400 mr-2`} >
                      <span style={{ borderBottom: '1px solid', marginTop: '5px', marginBottom: '10px' }}>Pricing</span>
                    </a>
                    <a href="#how-it-works" className={`cust-select-color dark-color me-4 fw-400 mr-2`}>
                      <span style={{ borderBottom: '1px solid', marginTop: '5px', marginBottom: '10px' }}>How it works</span>
                    </a>

                    <a href="#faqs" className={`cust-select-color dark-color me-4 fw-400 mr-2`}>
                      <span style={{ borderBottom: '1px solid', marginTop: '5px', marginBottom: '10px' }}>FAQs</span>
                    </a>

                  </Dropdown>


                  <a href="https://app.dripfunnel.com/login/" target="_blank" className={`dark-color f-400 }`} style={{ position: "relative" }}>
                    <span style={{ borderBottom: '1px solid', position: "absolute", bottom: "5px" }}>Login</span>
                  </a>


                  <Link to="https://app.dripfunnel.com/registration/" target="_blank">
                    <Button
                      className="border-0 mx-3 mt-2 btn-contact-us col-lg col-md-3 d-md-none d-block">
                      SignUpNow
                    </Button>
                  </Link>
                </Nav>

                <Nav className="ps-2">
                  <Link to="https://app.dripfunnel.com/registration/" target="_blank">
                    <Button
                      className="border-0 mb-2  mt-2 btn-contact-us col-lg  d-lg-block d-none py-2 ms-5"
                    >
                      <li className="menu-item menu-item-type-custom menu-item-object-custom nectar-regular-menu-item menu-item-btn-style-button_accent-color menu-item-hover-text-reveal-wave menu-item-has-icon menu-item-34">

                        <span className="nectar-menu-icon svg-icon">
                          <svg
                            role="presentation"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 32 32"
                            fill="white"
                          >
                            <path
                              d="M10.959 31.651c-0.093 0-0.191-0.019-0.280-0.060-0.299-0.139-0.451-0.472-0.361-0.789l3.628-12.745-8.113 1.695c-0.217 0.039-0.437-0.024-0.596-0.169-0.159-0.151-0.233-0.369-0.199-0.588l2.456-15.415c0.044-0.268 0.248-0.481 0.512-0.544l11.433-2.667c0.237-0.060 0.492 0.025 0.653-0.211 0.164 0.188 0.208 0.448 0.120.677l-3.319 8.601 9.243-2.399c0.268-0.075 0.552 0.031 0.713 0.257 0.159 0.225 0.164 0.528 0.008 0.752l-15.341 22.881c-0.129 0.195-0.341 0.301-0.557 0.301zM14.889 16.513c0.184 0 0.361 0.076 0.487 0.213 0.159 0.169 0.219 0.412 0.153 0.636l-2.773 9.751 12.012-17.916-8.804 2.283c-0.245 0.057-0.5-0.019-0.665-0.200-0.167-0.191-0.212-0.451-0.125-0.685l3.336-8.639-9.775 2.277-2.233 14.021 8.247-1.721c0.049-0.015 0.095-0.020 0.141-0.020z"
                            ></path>
                          </svg>
                        </span>
                        <span className="me-2 menu-title-text">SignUp Now</span>

                      </li>
                    </Button>
                  </Link>
                </Nav>
              </Navbar.Collapse>
            </>
          )}

        </Container>
      </Navbar>
    </div>
  );
};

export default Acnavbar;





