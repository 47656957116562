import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import video from "../assets/videos/video1.mp4";
import '../assets/scss/_common.scss';

function Services() {
  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    // Check if the window width is less than or equal to 768
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIsMobileView(true);
      } else {
        setIsMobileView(false);
      }
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Initial check on component mount
    handleResize();

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const textAlignment = isMobileView ? 'left' : 'center';
  const fontSize = isMobileView ? '12px' : '16px';
  const svgWidth = isMobileView ? '100px' : '150px';
  const svgHeight = isMobileView ? '100px' : '150px';

  const videoHeight = isMobileView ? '220px' : '500px';

  return (
    <div className={`${isMobileView ? 'service-bg bg-cover position-relative  accordian-img' : 'service-bg bg-cover position-relative pb-md-4'}`}>
      <Container className="py-3 pt-lg-3 tabs-container pt-md-4">
        <div className="d-flex flex-column">
          <h2 className="fnt-20 fnt-lg-32 fw-700 py-1 text-center text-md-left mt-md-2" style={{ color: 'black' }}>
            <div   className="position-relative pb-md-4">
            </div>
            <h2 className={`${isMobileView ? 'accordian-img fs-6  py-1 text-md-left mt-md-3' : 'fnt-20 fnt-lg-32 fw-700  text-center text-md-left '}`} style={{ color: 'black', textAlign: textAlignment, fontSize: fontSize }}>
              <svg
                className={`${isMobileView ? 'svg-sm-circle' : 'svg-circle'}`}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 100"
                width={svgWidth}
                height={svgHeight}
                preserveAspectRatio="xMidYMid meet">
                <circle
                  cx="50"
                  cy="50"
                  r="50"
                  fill="#ffc4b3"
                />
              </svg>
              <span className={`${isMobileView ? 'fs-5 circle-service-page' : 'display-5  fw-medium service-line-height service-margin circle-service-page'}`}>Imagine having a 24/7 marketing </span>
              <br />
              <span className={`${isMobileView ? 'fs-5 circle-service-page' : 'display-5  fw-medium service-line-height service-margin circle-service-page middle-text '}`}>expert at just fraction of the cost of a</span>
              <br />
              <div className={`${isMobileView ? 'mr-5 fs-5 circle-service-page' : 'display-5  fw-medium service-line-height services-left pe-5 circle-service-page'}`} style={{ textAlign: textAlignment }}>
                <span >full-time hire.</span>
                <br />
                <svg
                  className="nectar-scribble squiggle-underline"
                  role="presentation"
                  viewBox="-347 -30.1947 694 192.38"
                  preserveAspectRatio="none"
                  style={{ width: '20%' }}
                >
                  <path
                    style={{ animationDuration: '1.6s' }}
                    d="M-335,54 C-335,54 -171,-58 -194,-3 C-217,52 -224.1199951171875,73.552001953125 -127,11 C-68,-27 -137,50 -33,42 C31.43899917602539,37.042999267578125 147.14700317382812,-29.308000564575195 335,2"
                    stroke="#318fff"
                    pathLength="1"
                    strokeWidth="16"
                    fill="none"
                  />
                </svg>

              </div>
              <br />
            </h2>
          </h2>
          <span className={`${isMobileView ? 'accordian-img fw-500' : 'h-4 fs-6 service-page'}`} style={{ color: 'black', textAlign: 'left', margin: '0 auto' }}>
            <span className={`${isMobileView ? 'fs-6' : 'fs-5 me-5 lh-base'}`}>Convert leads into customers and then turn those customers into loyal</span>
            <br />
            <span className={`${isMobileView ? 'fs-6' : 'fs-5 lh-base'}`}>fans of your brand by leveraging next-generation automation and AI. Yes,</span>
            <br />
            <span className={`${isMobileView ? 'fs-6' : 'fs-5 lh-base'}`}>it really can be automated, and no, you're not dreaming.</span>
          </span>
        </div>
        <div className={`${isMobileView ? 'accordian-img' : ''}`}>
          <div  className={`${isMobileView ? 'd-flex justify-content-left my-3' : 'd-flex justify-content-center mb-3'}`}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="50"
              height="50"
              viewBox="0 0 24 24"
            >

              <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10s10-4.48 10-10S17.52 2 12 2zm-2 14.5v-9l8 4.5l-8 4.5z" />
            </svg>

            <p id="services" className={`${isMobileView ? ' fw-600 mt-3' : 'fs-2 fw-bold'}`}>
              Watch Our 90 Sec Explainer Video
            </p>
          </div>

          <iframe
            width="100%"
            height={videoHeight}
            src="https://www.youtube.com/embed/28B_5oWp7FM"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>

        </div>

      </Container>
    </div>
  );
}

export default Services;
